import React from "react";
import { Link } from "react-router-dom";
const ChannelList = (props) => {
  const channels = props.channels;

  return (
    <div>
      <Link to={`/`} key="all">
        <ChannelItem channel="all" />
      </Link>
      {channels &&
        channels.map((channel) => (
          <Link to={`/channel/${channel.name}`} key={channel.name}>
            <ChannelItem channel={channel.name} count={channel.count}/>
          </Link>
        ))}
    </div>
  );
};
export default ChannelList;

const ChannelItem = (props) => {
  const channel = props.channel;
  const count = props.count

  return <div>{channel} ({count})</div>;
};
