import React, {useState, useEffect} from "react";
import Item from "./Item";
import axios from "axios";

import { useParams } from 'react-router-dom';

const limit = 20;

const ItemList = (props) => {
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [page, setPage] = useState(0);
  const params = useParams();
  const baseurl = "13.64.93.38:8080"
  console.log(params);
  const readItem = (item) => {
    console.log("click " + item.link);
    axios.put(`http://${baseurl}/api/item/${item.id}/read`);
    setItems((items) => items.filter((it) => it.id !== item.id));
  };

  // when page change, do loading
  useEffect(() => {
    
    const loadItems = async () => {
      setIsLoading(true);
      try {
        let url = `http://${baseurl}/api/items?limit=${limit}&offset=${
          page * limit
        }`;
        if (params.id) {
          url += `&channel=${params.id}`;
        }
        const response = await axios.get(url);
        if (response.data.items && response.data.items.length > 0) {
          setItems((items) => [...items, ...response.data.items]);
          setErrorMsg("");
        }
      } catch (error) {
        console.error(error);
        setErrorMsg("Error while loading data. Try again later.");
      } finally {
        setIsLoading(false);
      }
    };
    loadItems();
  }, [page, params.id]);

  useEffect(() => {
    setItems((items) => [])
    setPage(0);
  }, [params.id])

  const loadMore = () => {
    setPage((page) => page + 1);
  };

  return (
    <div>
      <div className="content">
        {items &&
          items.map((item) => (
            <Item key={item.title} item={item} onReadItem={readItem} />
          ))}
      </div>
      {errorMsg && <p className="errorMsg">{errorMsg}</p>}
      <div className="load-more">
        <button onClick={loadMore} className="btn-grad">
          {isLoading ? "Loading..." : "Load More"}
        </button>
      </div>
    </div>
  );
};
export default ItemList;
