import React from "react";
import "./Item.css";
// import axios from "axios";

const Item = (props) => {
  const { channel, title, link, published_at } = props.item;
  const handleClick = () => {
    props.onReadItem(props.item);
  };

  return (
    <div
      className="item"
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleClick}
      >
        {channel}, {title}, {published_at}
      </a>
      <button
        onClick={handleClick}
        style={{
          background: "#4facfe",
          border: "none",
          color: "#fff",
          padding: "2px 2px",
          borderRadius: "5px",
          cursor: "pointer",
        }}
      >
        read
      </button>
    </div>
  );
};

export default Item;
