import React, { useState, useEffect } from "react";
import Header from "./components/Header";
import ItemList from "./components/ItemList";
import axios from "axios";
import ChannelList from "./components/ChannelList";
import AddChannelForm from "./components/AddChannelForm";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

const baseurl = "13.64.93.38:8080"

const App = () => {
  const [channels, setChannels] = useState([]);
  const [isAddChannelForm, setAddChannelForm] = useState(false);

  const handleAddChannelClick = () => {
    setAddChannelForm(true);
  };

  const handleAddChannelSubmit = (channel) => {
    console.log(channel);
    setChannels((channels) => [...channels, channel]);
    axios.post(`http://${baseurl}/api/channels`, {
      name: channel.name,
      link: channel.link,
    });
    setAddChannelForm(false);
  };

  useEffect(() => {
    const loadChannel = async () => {
      try {
        const url = `http://${baseurl}/api/channels`;
        const response = await axios.get(url);
        if (response.data.channels && response.data.channels.length > 0) {
          setChannels(response.data.channels);
        }
      } catch (err) {
        console.error(err);
      } finally {
      }
    };
    loadChannel();
  }, []);

  return (
    <div className="main-section">
      <Router>
        <Header />
        <div className="container">
          {isAddChannelForm && (
            <AddChannelForm onSaveChannel={handleAddChannelSubmit} />
          )}
          <div className="side">
            <ChannelList
              channels={channels}
            />
            <button onClick={handleAddChannelClick}>Add Channel</button>
          </div>
          <div className="content">
            <Routes>
              <Route path="/" element={<ItemList />} />
              <Route path="/channel/:id" element={<ItemList />} />
            </Routes>
          </div>
        </div>
      </Router>
    </div>
  );
};

export default App;
