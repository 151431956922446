import React, { useState } from "react";

const AddChannelForm = (props) => {
  const [userInput, setUserInput] = useState({
    name: "",
    link: "",
  });

  const nameChangeHandler = (event) => {
    setUserInput({
      ...userInput,
      name: event.target.value,
    });
  };

  const linkChangeHandler = (event) => {
    setUserInput({
      ...userInput,
      link: event.target.value,
    });
  };

  const submitHandler = (event) => {
    // don't reload page (form submit will try to reload page)
    event.preventDefault();

    const channel = {
      name: userInput.name,
      link: userInput.link,
    };

    props.onSaveChannel(channel);
    setUserInput({
      name: "",
      channel: "",
    });
  };
  return (
    <div className="form-overlay">
      <form className="form-popup" onSubmit={submitHandler}>
        {/* Form fields go here */}
        <input 
        type="text" 
        placeholder="channel"
        onChange={nameChangeHandler} />
        <input 
        type="text" 
        placeholder="link"
        onChange={linkChangeHandler} />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};
export default AddChannelForm;
